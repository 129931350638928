<template>
  <div class="flex-col page">
    <div class="flex-col justify-start self-stretch  section relative">
      <div class="tabs">
        <img class="image pos" src="../../img/3f16e645b14dc2fbd279b7069d3101bd.png" />
        <img class="image_2 pos_2" src="../../img/c7739287396e42eb3563ca8077cb6970.png" />
        <span class="font_2 pos_6" :class="tabsIndex == 0 ? 'font_2_choose' : ''" @click="tabsClick(0)">关于我们</span>
        <span class="font_2 pos_7" :class="tabsIndex == 1 ? 'font_2_choose' : ''" @click="tabsClick(1)">产品</span>
        <!-- <span class="font_2 pos_8" :class="tabsIndex == 2 ? 'font_2_choose' : ''" @click="tabsClick(2)">服务案例</span> -->
        <span class="font_2 pos_9" :class="tabsIndex == 3 ? 'font_2_choose' : ''" @click="tabsClick(3)">技术与保障</span>
        <span class="font_2 pos_10" :class="tabsIndex == 4 ? 'font_2_choose' : ''" @click="tabsClick(4)">合作</span>
        <span class="text_3 pos_11">PIN XUN KE JI</span>
      </div>
    </div>
    <div class="flex-col justify-start section">
      <div class="flex-col section_2">
        <div class="flex-col items-start mt-245 items-center">
          <div class="text_8">为用户提供一站式的产品与服务</div>
          <div class="font text_9">针对各行业、各领域的业务特性，打造个性化的行业及领域解决方案</div>
        </div>
      </div>
    </div>
    <div class="flex-col group_3">
      <div class=" ">
        <div class=" text_10 ">亥时科技核心产品介绍</div>
        <div class="text_11 mt-26">你可以选择任亥时科技任意一产品，来帮助企业实现销售增长</div>
        <div class="grid">
          <div class="flex-col justify-start relative grid-item" v-for="(item, index) in list" :key="index">
            <div class="flex-col relative section_18">
              <div class="flex-row justify-between self-stretch">
                <span class="font_4">{{ item.name }}</span>
              </div>
              <span class="self-start font_15 text_21">{{ item.desc }}</span>
              <div class="flex-row justify-between items-center self-stretch group_31" @click="productDetail(item.url)">
                <span class="font_19">查看更多</span>
                <img class="image_9 image_23" src="../../img/80c8d792cb9c4753eadbef23d3fc3de2.png" />
              </div>
            </div>
            <img class="image_18 pos_21" :src="item.src" />
          </div>
        </div>
      </div>
      <!-- <div class="flex-row justify-center group_17">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div> -->
    </div>
    <div class="flex-col group_18">
      <div class="flex-row justify-between section_9">
        <div class="flex-col self-center group_19">
          <img class="self-center image_41" src="../../img/25bc506b1a2d38ef4092bcfd5b3c150f.png" />
          <span class="self-center font_3 text_69">深圳亥时科技有限公司</span>
          <span class="self-center text_73">15136339268</span>
          <div class="self-stretch group_23">
            <span class="font_14 text_77">
              地址：深圳市罗湖区瑞思大厦2102
            </span>
          </div>
        </div>
        <div class="self-center section_10"></div>
        <div class="flex-col items-start ">
          <span class="font_13">产品体系</span>
          <span class="font_14 text_66">远程踏勘管理系统</span>
          <span class="font_14 text_70">友推云·电商SaaS平台</span>
          <span class="font_14 text_74">视频资源管理AI工具</span>
          <span class="font_14 text_80">亥时学习平台</span>
          <span class="font_14 text_81">文明积分银行</span>
          <span class="font_14 text_80">邻里中心智慧平台</span>
        </div>
        <div class="flex-col items-start">
          <span class="font_13 text_64">解决方案</span>
          <span class="font_14 text_67">智慧社区</span>
          <span class="font_14 text_71">智慧环卫</span>
          <span class="font_14 text_75">食品药品安全</span>
        </div>
        <!-- <div class="flex-col items-start self-start group_20">
          <span class="font_13 text_65">服务体系</span>
          <span class="font_14 text_68">软件开发</span>
          <span class="font_14 text_72">信息化咨询</span>
          <span class="font_14 text_76">企业上云</span>
        </div> -->
        <div class="self-center section_10"></div>
        <div class="flex-col self-center">
          <div class="flex-row">
            <div class="flex-col justify-start relative group_21">
              <div class="group_22"></div>
              <img class="image_43 pos_72" src="../../img/b9138baec0eb743ec366219cb10c477c.png" />
              <img class="image_42 pos_70" src="../../img/c71d4904444746a4da7224d79a321bd2.png" />
            </div>
            <div class="ml-24 flex-col justify-start relative group_21">
              <div class="group_22"></div>
              <div class="flex-col justify-start items-center image-wrapper pos_73">
                <img class="image_44" src="../../img/6a905240878593e4810d0748bb252371.png" />
              </div>
              <img class="image_42 pos_71" src="../../img/c71d4904444746a4da7224d79a321bd2.png" />
            </div>
          </div>
          <div class="mt-26 flex-row group_24">
            <span class="font_15 text_78">微信小程序</span>
            <span class="ml-66 font_15 text_79">微信公众号</span>
          </div>
        </div>
      </div>
      <div class="flex-col justify-start items-center text-wrapper_5">
        <span class="font_6 text_83">Copyright © 2023 深圳亥时科技有限公司 版权所有豫ICP备18009817号</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      tabsIndex: 1,
      list: [{
        name: "远程踏勘管理系统",
        desc: '远程不见面，办理更及时，为企业及群众提供更加专业精准高效的神品服务',
        src: require('../../img/df3917a751a885a365a47db40298de92.png'),
        url: 'http://www.hnpinxun.cn/yuanchengtakan.html'
      }, {
        name: "党员双报到平台",
        desc: '实现“双报到双积分”一体化社区服务体系，运用云计算新型信息技术助推社区服务功能更便捷',
        src: require('../../img/d8f1dd7312fe8ef1020fe440ffeee9bf.png'),
        url: 'http://www.hnpinxun.cn/register.html'
      }, {
        name: "亥时 · 好商会平台",
        desc: ' 一键接入小程序平台提升数字化运营能力，利用创新的互联网思维为商协会赋能',
        src: require('../../img/6f0840b3f2313ce9b48881d368f517a3.png'),
        url: 'http://www.hnpinxun.cn/quanzi.html'
      }, {
        name: "亥时 · 企业成长平台",
        desc: '为企业提供智能服务，助力企业打造高凝聚力组织，赋能组织更智慧、高效',
        src: require('../../img/257e31352598400b06b8dfea9ccaea67.png'),
        url: 'http://hsxx.hnpinxun.cn/'
      }, {
        name: "友推云 · 会展平台",
        desc: '为展会提供展会管理、报名表单、手机验证、短信群发、数据统计等一站式解决方案',
        src: require('../../img/49441ddf0a6164b1df8eac014fe55532.png'),
        url: 'http://www.hnpinxun.cn/exhibition.html'
      }, {
        name: "活动报名签到工具",
        desc: '只需使用手机 ，即可快速生成发布活动，实时统计参会人员，统一管理在线报名，一键分享报名活动',
        src: require('../../img/afceadbab94516f3f0eabfca20b8d3ac.png'),
        url: 'http://www.hnpinxun.cn/activity.html'
      }, {
        name: "友推云 · 电商管理平台",
        desc: '共建新商业生态，为客户创造更大价值',
        src: require('../../img/6c9b8153e0cee1ea7dcaadf28302708d.png'),
        url: 'http://yt.hnpinxun.cn/'
      }, {
        name: "友推云 · 名片管理平台",
        desc: '让每一个销售成为销售达人，汇聚人脉，触达客户',
        src: require('../../img/47f1b00e15ef66a5c5d6b2ebc51ff326.png'),
        url: 'http://www.hnpinxun.cn/card.html'
      },
      {
        name: "谜局 · 剧本杀管理平台",
        desc: '为剧本杀行业轻松管理经营提供全方位服务',
        src: require('../../img/686ddab497a63ec178185857a7ae9a49.png'),
        url: 'http://www.hnpinxun.cn/scriptKill.html'
      }, {
        name: "友推云 · 微官网",
        desc: '差异化策略营销解决方案，展示产品、技术服务，宣传企业，获取更多商机',
        src: require('../../img/da8bcd2407db28879de5c612c06d496d.png'),
        url: 'http://www.hnpinxun.cn/product.html'
      },
      ]

    };
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    productDetail(e) {
      window.open(e, "_blank");
    },
    tabsClick(e) {
      if (e != 1) {
        this.$router.push('/')
        this.$router.push({ path: '/', query: { tabsIndex: e } });
      }
    }
  },
};
</script>

<style scoped lang="scss">
.ml-15 {
  margin-left: 15px;
}

.mt-3 {
  margin-top: 3px;
}

.ml-130 {
  margin-left: 130px;
}

.mt-245 {
  margin-top: 245px;
}

.mt-37 {
  margin-top: 37px;
}

.mt-17 {
  margin-top: 17px;
}

.ml-23 {
  margin-left: 23px;
}

.ml-21 {
  margin-left: 21px;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .tabs {
    height: 98px;
    // background: rgba(35, 66, 110, 0.7019607843);
    position: absolute;
    top: 0px;
    left: 0px;
    right: 20px;
    z-index: 999;
  }

  .section {
    background-image: url('../../img/83bf835f2376b09c47d788ad1622ce63.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .section_2 {
      padding: 123px 135px 289px;
      background-color: #23426e80;

      .text_8 {
        color: #ffffff;
        font-size: 100px;
        font-family: Source Han Sans CN;
        font-weight: 700;
        line-height: 68px;
        letter-spacing: 12px;
        text-shadow: 0px 7px #38383854;
        margin-bottom: 50px;
      }

      .font {
        font-size: 24px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
      }

      .text_9 {
        letter-spacing: 2.5px;
        text-align: center;
      }
    }

    .image {
      width: 53px;
      height: 54px;
    }

    .pos {
      position: absolute;
      left: 240px;
      top: 21px;
    }

    .image_2 {
      width: 94px;
      height: 23px;
    }

    .pos_2 {
      position: absolute;
      left: 308px;
      top: 29px;
    }

    .font_2 {
      font-size: 16px;
      font-family: PingFang;
      line-height: 16px;
      color: #ffffff;
      cursor: pointer;
    }

    .font_2_choose {
      padding-bottom: 8px;
      border-bottom: 3px solid #2c89fd;
    }

    .font_2:hover {
      color: #2c89fd;
    }


    .pos_6 {
      position: absolute;
      // right: 575px;
      right: 440px;
      top: 39px;
    }

    .pos_7 {
      position: absolute;
      // right: 488px;
      right: 354px;
      top: 39px;
    }

    .pos_8 {
      position: absolute;
      right: 359px;
      top: 39px;
    }

    .pos_9 {
      position: absolute;
      right: 225px;
      top: 39px;
    }

    .pos_10 {
      position: absolute;
      right: 147px;
      top: 39px;
    }


    .pos_4 {
      position: absolute;
      right: 246px;
      top: 37px;
    }

    .text_3 {
      color: #ffffff;
      font-size: 14px;
      font-family: PingFang;
      line-height: 12px;
    }

    .pos_11 {
      position: absolute;
      left: 308px;
      top: 55px;
    }

    .pos_13 {
      position: absolute;
      left: 220px;
      top: 250px;
    }

    .text-wrapper {
      padding: 14px 0;
      border-radius: 24px;
      width: 180px;
      border-left: solid 1px #f8faff;
      border-right: solid 1px #f8faff;
      border-top: solid 1px #f8faff;
      border-bottom: solid 1px #f8faff;

      .text_6 {
        color: #f8faff;
        font-weight: 700;
        line-height: 19px;
      }
    }

  }

  .grid {
    margin-top: 26px;
    width: 1450.5px;
    display: grid;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 0;
    column-gap: 12.5px;

    .grid-item {
      padding: 50px 0 10px;
      width: 476.5px;

      .section_18 {
        margin-right: 10px;
        padding: 245px 16px 0;
        background-color: #ffffff;
        box-shadow: 0px 9px 16px #3838381a;
        width: 466px;
        border-left: solid 1px #dedede;
        border-right: solid 1px #dedede;
        border-top: solid 1px #dedede;
        border-bottom: solid 1px #dedede;

        .image_20 {
          margin-right: 5px;
        }

        .text_21 {
          margin-top: 15px;
          line-height: 24px;
        }
      }

      .image_18 {
        width: 466px;
        height: 263px;
      }

      .pos_21 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }

      .section_19 {
        margin-right: 10px;
        padding: 245px 16px 0;
        background-color: #ffffff;
        width: 466px;
        border-left: solid 1px #dedede;
        border-right: solid 1px #dedede;
        border-top: solid 1px #dedede;
        border-bottom: solid 1px #dedede;

        .image_21 {
          margin-right: 4px;
          width: 47px;
          height: 18px;
        }

        .text_22 {
          margin-top: 16px;
          line-height: 15px;
        }

        .group_30 {
          padding: 0 2px;

          .image_22 {
            margin-right: 3px;
          }
        }

        .text_23 {
          margin-top: 15px;
        }

        .image_26 {
          margin-right: 4px;
          width: 21px;
          height: 18px;
        }

        .text_26 {
          margin-top: 16px;
          line-height: 15px;
        }

        .font_20 {
          font-size: 18px;
          font-family: MicrosoftYaHei;
          line-height: 16px;
          color: #333333;
        }

        .text_24 {
          line-height: 17px;
        }

        .image_27 {
          margin-right: 4px;
        }

        .text_27 {
          margin-top: 16px;
          line-height: 15px;
        }

        .text_25 {
          line-height: 17px;
        }

        .image_28 {
          margin-right: 5px;
        }

        .text_28 {
          margin-top: 16px;
          line-height: 15px;
        }
      }

      .group_31:hover {
        color: #2c89fd;
      }

      .group_31 {
        margin-top: 21px;
        padding: 16px 0;
        border-top: solid 1px #dedede;
        cursor: pointer;

        .image_23 {
          margin-right: 4px;
        }

        .image_24 {
          margin-right: 4px;
        }

        .image_25 {
          margin-right: 5px;
        }

        .image_29 {
          margin-right: 4px;
        }

        .image_30 {
          margin-right: 4px;
        }

        .image_31 {
          margin-right: 5px;
        }
      }

      .pos_22 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }

      .image_19 {
        width: 43px;
        height: 18px;
      }

      .pos_23 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }

      .pos_24 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }

      .pos_25 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }

      .pos_26 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }
    }
  }


  .group_3 {
    margin-top: 60px;
    padding: 0 236px;


    .text_10 {
      color: #333333;
      font-size: 42px;
      font-family: Source Han Sans CN;
      line-height: 40px;
    }

    .text_11 {
      line-height: 15px;
      color: #333333;
    }

    .group_17 {
      margin-top: 58px;
    }
  }

  .group_18 {
    margin-top: 75px;

    .section_9 {
      padding: 58px 240px 32px;
      background-color: #222222;

      .group_19 {
        width: 274px;

        .image_41 {
          width: 55px;
          height: 55px;
        }

        .text_69 {
          margin-top: 14px;
          color: #ffffff;
          font-weight: 700;
          line-height: 21px;
        }

        .text_73 {
          margin-top: 18px;
          color: #ffffff;
          font-size: 30px;
          font-family: MicrosoftYaHei;
          font-weight: 700;
          line-height: 24px;
        }

        .group_23 {
          margin-top: 36px;
          line-height: 20px;

          .text_77 {
            line-height: 20px;
          }
        }
      }

      .section_10 {
        background-color: #ffffff1f;
        width: 1px;
        height: 200px;
      }

      .font_13 {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        letter-spacing: 1.5px;
        line-height: 16px;
        font-weight: 700;
        color: #ffffff;
      }

      .font_14 {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        letter-spacing: 1.5px;
        line-height: 16px;
        color: #ffffff;
      }

      .text_66 {
        margin-top: 32px;
      }

      .text_70 {
        margin-top: 23px;
      }

      .text_74 {
        margin-top: 22px;
      }

      .text_80 {
        margin-top: 23px;
      }

      .text_64 {
        line-height: 17px;
      }

      .text_67 {
        margin-top: 33px;
        line-height: 15px;
      }

      .text_71 {
        margin-top: 23px;
      }

      .text_75 {
        margin-top: 23px;
        line-height: 15px;
      }

      .text_81 {
        margin-top: 24px;
        line-height: 15px;
      }

      .group_20 {
        margin-top: 15px;

        .text_65 {
          line-height: 17px;
        }

        .text_68 {
          margin-top: 33px;
          line-height: 15px;
        }

        .text_72 {
          margin-top: 24px;
          line-height: 15px;
        }

        .text_76 {
          margin-top: 23px;
          line-height: 15px;
        }
      }

      .group_21 {
        width: 130px;

        .group_22 {
          opacity: 0.4;
          height: 130px;
          border-left: dashed 1px #ffffff;
          border-right: dashed 1px #ffffff;
          border-top: dashed 1px #ffffff;
          border-bottom: dashed 1px #ffffff;
        }

        .image_43 {
          width: 106px;
          height: 106px;
        }

        .pos_72 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .image_42 {
          width: 130px;
          height: 130px;
        }

        .pos_70 {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        .image-wrapper {
          padding: 41px 0;
          background-image: url('../../img/4b64faa5aab9acfe49510fdf544c4ae3.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 106px;

          .image_44 {
            width: 24px;
            height: 24px;
          }
        }

        .pos_73 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .pos_71 {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }

      .group_24 {
        padding: 0 20px;

        .font_15 {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          letter-spacing: 1.5px;
          line-height: 16px;
          color: #dfdfdf;
        }

        .text_78 {
          line-height: 15px;
        }

        .text_79 {
          line-height: 15px;
        }
      }
    }

    .text-wrapper_5 {
      padding: 24px 0;
      background-color: #1b1b1b;

      .text_83 {
        line-height: 17px;
      }
    }
  }

  .font_3 {
    font-size: 20px;
    font-family: MicrosoftYaHei;
    letter-spacing: 2px;
    line-height: 20px;
    color: #333333;
  }

  .font_6 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 16px;
    color: #999999;
  }
}
</style>